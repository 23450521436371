input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #ffff;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: 0 0 0px 1000px #ffff inset;
  transition: background-color 5000s ease-in-out 0s;
}
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
*::-webkit-media-controls-panel {
  display: none !important;

  -webkit-appearance: none;
}

*::state(webkit-media-controls-play-button) {
  display: none !important;

  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;

  -webkit-appearance: none;
}

.rc-progress-circle-path {
  transition: all 1s ease-in-out !important;
}
.recharts-surface {
  background-color: #f5f4f4;
  border-radius: 10px;
  padding: 15px 0;
}

@media print {
  body,
  th,
  td {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
}
@media print {
  body {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    margin: 0;
    background-color: #fff;
    font-size: 12pt;
    line-height: 1.5;
  }
  @page {
    size: A4;
    margin: 0;
  }
}
.recharts-label {
  font-size: 13px;
}

@media only screen and (max-width: 575px) {
  .recharts-tooltip-wrapper {
    transform: translate(68px, -80px) !important;
  }
}
